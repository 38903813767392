import React from 'react';
import { colors, fontSizes, fontWeights, mq } from '../../theme';
import { gtag } from '../../helpers/gtag.js';
import Layout from '../../components/Layout';
import { Heading2, Paragraph } from '../../components/Heading/Text';
import WebsiteButton from '../../components/WebsiteButton';
import BackgroundImage from '../../components/BackgroundImage';
import Container from '../../components/Container';
import TickItem from '../../components/TickItem';
import Link from '../../components/Link';
import TelepilotReferencing from '../../components/TelepilotReferencing';
import GoogleReviews from '../../components/GoogleReviews/GoogleReviews';
import AllToOnePackage from '../../components/AllToOnePackage';
import Equipe from '../../images/carousel/equipe.png';
import SEO from '../../components/seo';
import FooterBanner from '../../components/FooterBanner/FooterBanner';

const FormationDrone = () => (
  <Layout>
    <SEO
      title="La formation drone télépilote diplomante pour le bâtiment"
      description="Web2vi propose sa formation innovante, spécialement pour les artisans du bâtiment, dans le but d'une utilisation du drone dans les règles pour leur métier du BTP."
    />
    <Container
      background={colors.neutralWhite}
      css={mq({
        zIndex: 1,
        paddingBottom: [0, 0],
        paddingTop: 0,
        minHeight: [550, 400, 500],
        position: 'relative',
        alignItems: 'center',
        justifyContent: ['inherit', 'center']
      })}
    >
      <Container.ContentLeft>
        <div
          css={mq({
            display: ['block', 'none', 'none'],
            position: ['absolute', 'relative'],
            bottom: 0,
            right: [517, 0],
            width: '100%',
            height: [550, 500, 400]
          })}
        >
          <BackgroundImage
            src="formations/Fred-telepilote.png"
            css={mq({
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'bottom center'
            })}
          />
        </div>
      </Container.ContentLeft>
      <Container.ContentRight>
        <div style={{ width: '100%', paddingBottom: 40 }}>
          <Heading2 primary light>
            La formation drone
            <br />
            <span>
              {' '}
              diplomante et dédiée
              <br /> aux artisans du bâtiment
            </span>
          </Heading2>
          <Paragraph css={{ marginTop: 30 }}>
            La solution Web2vi pour l&apos;utilisation
            <br /> du drone professionnel en toute légalité
          </Paragraph>
          <WebsiteButton
            href="http://info.web2vi.com/informations-formation-telepilote"
            mt={8}
          >
            Je souhaite plus d&apos;informations
          </WebsiteButton>
        </div>
      </Container.ContentRight>
    </Container>
    <AllToOnePackage />
    <Container fluid>
      <Container.ContentLeft>
        <div>
          <Heading2 light style={{ marginTop: 1 }}>
            Une innovation de service
            <br />
            <span>pensée spécialement pour les pros</span>
          </Heading2>
          <div
            css={mq({
              '> div': {
                lineHeight: ['initial', '100px']
              }
            })}
          >
            <TickItem>
              <b>Formation aux scénarios de vol S1 et S3</b> (zone non peuplée
              et zone peuplée, vols à&nbsp;vue).
            </TickItem>
            <TickItem>
              <b>Formation condensée</b> entre 3 et 5 jours permettant
              d&apos;adapter son investissement personnel à la préparation de
              l&apos;obtention des diplômes.
            </TickItem>
            <TickItem>
              <b>Formation en effectif restreint</b> de 3 à 5 personnes pour
              <br /> favoriser l&apos;apprentissage.
            </TickItem>
            <TickItem>
              <b> Formation compatible</b> avec les nouvelles normes européennes
              <br />
              prévues pour 2021.
            </TickItem>
            <TickItem>
              <b>La formation est labellisée Datadock.</b>
            </TickItem>
          </div>
          <div
            css={{
              marginTop: 60,
              display: 'flex',
              lineHeight: '24px'
            }}
          >
            <WebsiteButton href="http://info.web2vi.com/informations-formation-telepilote">
              Je souhaite plus d&apos;informations
            </WebsiteButton>
          </div>
        </div>
      </Container.ContentLeft>
      <Container.ContentRight>
        <img
          src={Equipe}
          alt="equipe formation"
          css={{
            height: 428,

            backgroundSize: 'auto 100%',
            position: 'relative',
            top: 37,
            left: 132
          }}
        />
        <p
          css={{
            margin: ['49px 0px 0px 219px'],
            fontWeight: 600,
            fontSize: 14
          }}
        >
          Les stagiaires en formation télépilote Web2vi avant vous !
        </p>
      </Container.ContentRight>
    </Container>
    <GoogleReviews />
    <p
      css={mq({
        backgroundColor: colors.brandColor,
        textAlign: ['center', 'left'],
        margin: `0 auto`,
        padding: '40px 19px',
        lineHeight: '48px',
        color: colors.neutralDarker,
        fontWeight: fontWeights.semiBold,
        fontSize: fontSizes.larger,
        display: 'block'
      })}
    >
      Un gain de temps mesurable. Une bibliothèque inégalée.
      <br css={mq({ display: ['block', 'none'] })} />
      <Link
        onClick={() => {
          gtag('event', 'Click', {
            event_category: 'ExternalLink',
            event_label: 'Je demande ma démo gratuite (Footer)'
          });
        }}
        href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
      >
        <u>Je demande ma démo gratuite</u>
      </Link>{' '}
    </p>
    <TelepilotReferencing />
    <FooterBanner />
  </Layout>
);

export default FormationDrone;
