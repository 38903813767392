import React from 'react';
import { fontSizes, colors } from '../../theme';
import BackgroundImage from '../BackgroundImage';

const TickItem = ({ children, isWhite }) => {
  return (
    <BackgroundImage
      src="tick.svg"
      css={{
        fontSize: fontSizes.medium,
        paddingLeft: 30,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 16,
        letterSpacing: 1,
        backgroundPosition: 'top 7px left 0',
        marginBottom: 12
      }}
    >
      <p
        css={{
          color: isWhite ? colors.neutralWhite : 'initial',
          lineHeight: '30px'
        }}
      >
        {children}
      </p>
    </BackgroundImage>
  );
};

export default TickItem;
