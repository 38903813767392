import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { colors, mq } from '../../theme';
import Container from '../Container';
import { Heading2, Paragraph } from '../Heading/Text';
import WebsiteButton from '../WebsiteButton';
import rating from '../../images/avis/five-stars.png';

const GoogleReviews = () => {
  const { allGooglePlacesReview } = useStaticQuery(graphql`
    query {
      allGooglePlacesReview(sort: { fields: time, order: DESC }) {
        nodes {
          relative_time_description
          time
          author_name
          language
          profile_photo_url
          text
        }
      }
    }
  `);

  const reviews = allGooglePlacesReview.nodes.map(r => (
    <div
      key={r.text}
      css={{
        backgroundColor: '#fff',
        margin: ['30px 0px 0px 5px'],
        padding: '20px',
        borderRadius: 10,
        width: '100%'
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <img height="60" width="60" src={r.profile_photo_url} alt="profil" />
        </div>
        <div
          css={{
            display: 'flex',
            marginLeft: '10px',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div>
            <strong>{r.author_name}</strong>
          </div>
          <div css={{ fontSize: 10, display: 'flex', alignItems: 'center' }}>
            <img width="98px" src={rating} alt={`${r.author_name} rating`} />
            <span css={{ marginLeft: '5px' }}>
              {r.relative_time_description}
            </span>
          </div>
        </div>
      </div>

      <p css={{ marginTop: '10px' }}>{`${r.text.substring(0, 250)} ...`}</p>
    </div>
  ));

  return (
    <Container background={colors.neutralDarker}>
      <Container.ContentLeft
        css={mq({ display: ['block', 'none'], paddingRight: 140 })}
        size={55}
      >
        <Heading2>
          <span>Nos clients en parlent</span> mieux que&nbsp;nous
        </Heading2>
        <Paragraph style={{ color: '#FFF' }}>
          Logiciel de gestion ou diplôme de télépilote, ils ont effectué une
          formation Web2vi et donnent leur avis sur la fiche Google Web2vi.
          <br />
          <br />
          Ce n&apos;est pas nous qui le disons, ce sont nos clients!
        </Paragraph>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 65,
            '& > button': { marginBottom: 20 }
          }}
        >
          <WebsiteButton href="http://info.web2vi.com/informations-formation-telepilote">
            Je souhaite plus d&apos;informations
          </WebsiteButton>
        </div>
      </Container.ContentLeft>
      <Container.ContentRight>
        <div css={mq({ display: ['none', 'block'] })}>
          <div css={{ textAlign: 'center' }}>
            <Heading2>Ce que nos clients pensent de nous</Heading2>
          </div>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: [65, 35],
              '& > button': { marginBottom: 20 }
            }}
          >
            <div
              css={mq({
                marginTop: [30, 0],
                marginBottom: [0, 10],
                display: 'flex',
                lineHeight: '24px'
              })}
            />
            <WebsiteButton href="http://info.web2vi.com/informations-formation-telepilote">
              Je souhaite plus d&apos;informations
            </WebsiteButton>
          </div>
        </div>
        {reviews}
      </Container.ContentRight>
    </Container>
  );
};

export default GoogleReviews;
