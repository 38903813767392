import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { colors, fontWeights, MediaQuery, mq } from '../../theme';
import Container from '../Container';
import { Heading3 } from '../Heading/Text';
import PointImage from '../PointImage';
import WebsiteButton from '../WebsiteButton';
import { chunk } from '../../helpers/chunk.js';

const AllToOnePackage = () => {
  const {
    allAllToOnePackageJson: { nodes: data }
  } = useStaticQuery(graphql`
    query AllToOnePackageQuery {
      allAllToOnePackageJson(limit: 3) {
        nodes {
          id
          title
          image
        }
      }
    }
  `);

  return (
    <div
      css={{
        backgroundColor: colors.neutralDarker,
        color: colors.neutralWhite,
        overflow: 'hidden'
      }}
      style={{ padding: '0 143px 0' }}
    >
      <Container
        css={mq({
          flexDirection: 'column',
          alignItems: 'center'
        })}
      >
        <MediaQuery>
          {({ isMobile }) => (
            <div
              css={mq({
                boxSizing: 'border-box',
                display: ['flex'],
                flexDirection: 'column',
                alignItems: 'center',
                width: ['100%', '100%'],
                margin: 'auto'
              })}
            >
              <Heading3 textAlign="center" style={{ marginBottom: 106 }}>
                Une formule <span>tout en 1</span>
              </Heading3>
              <p
                style={{
                  fontSize: 73,
                  color: '#f4c21a',
                  fontWeight: 600,
                  marginBottom: -110,
                  marginLeft: 359
                }}
              >
                +
              </p>
              <p
                style={{
                  fontSize: 73,
                  color: '#f4c21a',
                  fontWeight: 600,
                  marginBottom: -129,
                  marginRight: 384
                }}
              >
                +
              </p>
              {chunk(
                data.map(({ image, title }, key) => (
                  <PointImage
                    width={['33%', '100%']}
                    key={key}
                    source={image}
                    number={`0${key + 1}`}
                  >
                    <span
                      css={{
                        textTransform: 'uppercase',
                        fontWeight: fontWeights.semiBold,
                        marginLeft: '73px',
                        marginRight: '56px'
                      }}
                    >
                      {title}
                    </span>
                  </PointImage>
                )),
                isMobile ? 1 : 3
              ).map((slide, index) => (
                <div
                  key={index}
                  css={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%'
                  }}
                >
                  {slide}
                </div>
              ))}
            </div>
          )}
        </MediaQuery>
        <div css={mq({ display: ['block', 'none'] })}>
          <WebsiteButton href="http://info.web2vi.com/informations-formation-telepilote">
            Je souhaite plus d&apos;informations
          </WebsiteButton>
        </div>
      </Container>
    </div>
  );
};

export default AllToOnePackage;
