import React from 'react';
import { colors, mq, fontSizes } from '../../theme';
import Container from '../Container';
import { Heading2, Heading3, Paragraph } from '../Heading/Text';

const TelepilotReferencing = () => {
  return (
    <>
      <div
        css={{
          backgroundColor: colors.neutralDarker,
          color: colors.neutralWhite
        }}
      >
        <Heading2
          light
          css={mq({
            textAlign: 'center',
            margin: 'auto',
            paddingTop: 80,
            color: colors.neutralWhite
          })}
        >
          La première <span>formation drone</span> diplômante spécialisée
          <br /> pour les artisans du bâtiment
        </Heading2>
        <Container fluid css={mq({ flexDirection: ['row', 'column'] })}>
          <Container.ContentLeft css={mq({ paddingRight: [60, 0] })}>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              La formation pilote de drone obligatoire pour utiliser un drone
              professionnel
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              La question de l’<strong>autorisation vol drone</strong> revient
              régulièrement chez les professionnels du bâtiment. Le drone est
              utile pour les métiers du BTP mais comment rester en règle avec la
              législation ? Inutile d’y aller par quatre chemins si l’on veut
              adapter sa pratique du drone à différents types de chantiers, de
              lieux, de situations, il faut avoir le statut de{' '}
              <strong>pilote de drone</strong>.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Et seulement l’obtention d’un diplôme reconnu par la DGAC vous
              donnera ce statut. L’examen se compose d’une partie pratique et
              théorique. Il faut obtenir les deux pour être pilote de drone. Il
              convient donc de recourir à une{' '}
              <strong>formation pilote de drone</strong> labellisée et reconnue
              DGAC. La <strong>formation drone professionnel</strong> puis le
              diplôme vous permettra de faire des demandes d’autorisation vol
              drone à chaque fois que vous en avez besoin. Ce processus est très
              rapide, c’est l’obtention du diplôme qui représente la plus grande
              charge de travail seulement une fois et au départ.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Web2vi propose sa <strong>formation drone télépilote</strong> pour
              aider les artisans du bâtiment que nous suivons habituellement,
              dans la transition vers le drone professionnel avec le{' '}
              <strong>brevet pilote drone</strong>.
            </Paragraph>
          </Container.ContentLeft>
          <Container.ContentRight>
            <Heading3
              css={{
                color: colors.brandColor,
                lineHeight: '120%'
              }}
            >
              Un brevet pilote drone orienté vers le métier de couvreur
            </Heading3>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Le drone pour le métier de couvreur représente un gain de temps et
              de sécurité immense. Il est très important d’aider ce corps de
              métier à opérer sa transition vers des outils technologiques qui
              améliorent les conditions de travail et la rentabilité des
              entreprises de couverture.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Le <strong>brevet pilote drone</strong> est le même pour tout le
              monde, pour tout candidat à la{' '}
              <strong>formation drone professionnel</strong>. La formation
              télépilote Web2vi est une solution de service spécialement conçue
              pour les artisans du bâtiment et notamment les couvreurs.
            </Paragraph>
            <Paragraph
              css={{
                fontSize: fontSizes.medium
              }}
            >
              Tout est mis en place pour que la{' '}
              <strong>formation pilote de drone</strong> soit dispensée de façon
              à orienter les connaissances nécessaires pour le{' '}
              <strong>diplôme drone</strong> vers des situations du métier de
              couvreur. Toute la <strong>formation drone</strong> est modulable
              pour que l’artisan ou le chef d’entreprise puisse choisir le
              meilleur mode de formation en fonction de son activité.
            </Paragraph>
          </Container.ContentRight>
        </Container>
      </div>
    </>
  );
};

export default TelepilotReferencing;
